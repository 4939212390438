
import { formatterBooking } from "./Booking";

export function formatterBookingTime(data){
    return {
        id: parseInt(data.id),
        minutes: data.minutes,
        comment: data.comment,
        booking: (data.booking) ? formatterBooking(data.booking) : null,
        type: (data.type) ? formatterBookingTimeType(data.type) : null,
        userBalanceId: (data.balance_user_id) ? parseInt(data.balance_user_id) : null,
        createdAt: new Date(data.created_at),
    }
}
export function formatterBookingTimeType(data){
    return {
        id: parseInt(data.id),
        name: data.name,
        slug: data.slug,
    }
}
export function formatterBookingTimeErrors(data){
    let errorValids = {};
    let valids = {
        minutes: 'minutes',
        payment_type_id: 'paymentTypeId',
    };
    Object.keys(data).forEach((key)=>{
        let item = data[key];
        if(key in valids) errorValids[valids[key]] = item;
    })
    return {
        valids: errorValids,
    };
}