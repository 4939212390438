import TypeConst from "./ListConst";

export default function todosReducer(state = {
  users: {},
  orders: {},
  products: {},
  paymentTypes: {},
  paymentStatuses: {},
  bookingTypes: {},
  bookingStatuses: {},
  bookingBalanceTypes: {},
  bookingTimeIds: {},
}, action) {
  switch (action.type) {
    case TypeConst.UPDATE_USERS:
      return {
          ...state,
          users: {...state.users, ...action.users},
      };
    case TypeConst.UPDATE_ORDERS:
      return {
        ...state,
        orders: {...state.orders, ...action.orders},
      };
    case TypeConst.UPDATE_PRODUCTS:
      return {
        ...state,
        products: {0:0, ...state.products, ...action.products},
      };
    case TypeConst.UPDATE_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: {...state.paymentTypes, ...action.paymentTypes},
      };
    case TypeConst.UPDATE_PAYMENT_STATUSES:
      return {
        ...state,
        paymentStatuses: {...state.paymentStatuses, ...action.paymentStatuses},
      };
    case TypeConst.UPDATE_BOOKING_TYPES:
      return {
        ...state,
        bookingTypes: {...state.bookingTypes, ...action.bookingTypes},
      };
    case TypeConst.UPDATE_BOOKING_STATUSES:
      return {
        ...state,
        bookingStatuses: {...state.bookingStatuses, ...action.bookingStatuses},
      };
    case TypeConst.UPDATE_BOOKING_BALANCE_TYPES:
      return {
        ...state,
        bookingBalanceTypes: {...state.bookingBalanceTypes, ...action.bookingBalanceTypes},
      };
    case TypeConst.UPDATE_BOOKING_TIME_IDS:
      return {
        ...state,
        bookingTimeIds: {...state.bookingTimeIds, ...action.bookingTimeIds},
      };
    default:
        return state;
  }
}
