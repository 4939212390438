import React, { useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BookingsRow from "./__row";
import InputNumber from "../input/__number";
import ApiBooking from "../../api/ApiBooking";
import SlotTimesDate from "../slot-times-date/slot-times-date";
import { setIsLoadUpdate } from "../../reducers/BookingPanel/BookingPanelActions";
import { loadConnectionsForBookings } from "../../reducerApi";
import DateLib from "../../inc/DateLib";

import style from "./style";
import "./styles.css";

const apiBooking = new ApiBooking({});

function Bookings({date}){
    const dispatch = useDispatch();
    const isLoadUpdate = useSelector((state) => state.bookingPanel.isLoadUpdate);
    const [bookings, setBookings] = useState([]);
    const [id, setId] = useState('');

    const actionEditId = (e) => {setId(e.target.value)}
    const loadBookings = async ({id, dispatch}) => {
        let params = {};
        const data = await apiBooking.getBookings({id, ...params});
        if(data){
            loadConnectionsForBookings({dispatch, bookings: data});
            setBookings(data);
        }
    }
    useLayoutEffect(()=>{
        if(
            id.length > 1
        ) loadBookings({id, dispatch});
    }, [id, dispatch]);
    useLayoutEffect(()=>{
        const load = async ()=>{
            dispatch(setIsLoadUpdate(false));
            loadBookings({id, dispatch});
        }
        if(isLoadUpdate) load();
    }, [dispatch, isLoadUpdate, id]);
    let slotTimeDate = null;
    return <>
        <div className="bookings__search">
            <InputNumber
                label="Номер"
                variant="standard"
                value={id}
                onChange={actionEditId}
                sx={style.input}
            />
        </div>
        {bookings.map((booking, i)=>{
            let slotTimesDateTitle = null;
            if(!slotTimeDate || slotTimeDate.getTime() !== booking.slotTimeDate.getTime()){
                slotTimeDate = booking.slotTimeDate;
                slotTimesDateTitle = <div className="bookings__date">
                        <SlotTimesDate date={slotTimeDate}/>
                    </div>
            }
            return <>
                {slotTimesDateTitle}
                <div key={i}  className={`grid-booking-content__bookings-row-wrapper bookings__item`}>
                    <div className="sticky-booking-content__bookings-row-col-1 bookings__item-datetime-wrapper">
                        <div className="bookings__item-datetime">
                            <div className="bookings__item-time">
                                {DateLib.formatterTime(booking.slotTimeDateTimeStart)}
                            </div>
                            <div className="bookings__item-date">
                                {DateLib.formatterDate(booking.slotTimeDateTimeStart)}
                            </div>
                        </div>
                    </div>
                    <BookingsRow 
                        isSlotTimeDateTime={true}
                        booking={booking}
                    />
                </div>
            </>
        })}
    </>
}

export default Bookings;