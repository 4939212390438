import TypeConst from "./ListConst";

/* START UPDATE_USERS */
export function updateUsers(list){
    return {
        type: TypeConst.UPDATE_USERS,
        users: list,
    }
}
export function updateUser(id, item){
    let list = {};
    list[id] = item;
    return updateUsers(list);
}
/* END UPDATE_USERS */

/* START UPDATE_ORDERS */
export function updateOrders(list){
    return {
        type: TypeConst.UPDATE_ORDERS,
        orders: list,
    }
}
export function updateOrder(id, item){
    let list = {};
    list[id] = item;
    return updateOrders(list);
}
/* END UPDATE_ORDERS */

/* START UPDATE_ORDERS */
export function updateProducts(list){
    return {
        type: TypeConst.UPDATE_PRODUCTS,
        products: list,
    }
}
export function updateProduct(id, item){
    let list = {};
    list[id] = item;
    return updateProducts(list);
}
/* END UPDATE_ORDERS */

/* START UPDATE_PAYMENT_TYPES */
export function updatePaymentTypes(list){
    return {
        type: TypeConst.UPDATE_PAYMENT_TYPES,
        paymentTypes: list,
    }
}
export function updatePaymentType(id, item){
    let list = {};
    list[id] = item;
    return updatePaymentTypes(list);
}
/* END UPDATE_PAYMENT_TYPES */

/* START UPDATE_PAYMENT_STATUSES */
export function updatePaymentStatuses(list){
    return {
        type: TypeConst.UPDATE_PAYMENT_STATUSES,
        paymentStatuses: list,
    }
}
export function updatePaymentStatus(id, item){
    let list = {};
    list[id] = item;
    return updatePaymentStatuses(list);
}
/* END UPDATE_PAYMENT_STATUSES */

/* START UPDATE_BOOKING_TYPES */
export function updateBookingTypes(list){
    return {
        type: TypeConst.UPDATE_BOOKING_TYPES,
        bookingTypes: list,
    }
}
export function updateBookingType(id, item){
    let list = {};
    list[id] = item;
    return updateBookingTypes(list);
}
/* END UPDATE_BOOKING_TYPES */

/* START UPDATE_BOOKING_STATUSES */
export function updateBookingStatuses(list){
    return {
        type: TypeConst.UPDATE_BOOKING_STATUSES,
        bookingStatuses: list,
    }
}
export function updateBookingStatus(id, item){
    let list = {};
    list[id] = item;
    return updateBookingStatuses(list);
}
/* END UPDATE_BOOKING_TYPES */

/* START UPDATE_BOOKING_BALANCE_TYPES */
export function updateBookingBalanceTypes(list){
    return {
        type: TypeConst.UPDATE_BOOKING_BALANCE_TYPES,
        bookingBalanceTypes: list,
    }
}
export function updateBookingBalanceType(id, item){
    let list = {};
    list[id] = item;
    return updateBookingBalanceTypes(list);
}
/* END UPDATE_BOOKING_BALANCE_TYPES */


/* START UPDATE_BOOKING_BALANCE_TYPES */
export function updateBookingTimeIds(list){
    return {
        type: TypeConst.UPDATE_BOOKING_TIME_IDS,
        bookingTimeIds: list,
    }
}
/* END UPDATE_BOOKING_BALANCE_TYPES */